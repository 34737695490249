<template>
	<div>
		<a-config-provider :locale="zh_CN">
			<a-card>
				<a-row style="margin-bottom: 20px;">
					<a-col :span="24">
						<a-range-picker @change="DiscoverytimeChange" :placeholder="[l('StartTime'), l('WEEndDate')]" v-model="nowTime"
						 style=" width: 200px;text-align: center;margin-right: 10px;margin-top: 2px;" />
						<!-- <a-date-picker placeholder="请选择年" mode="year" v-model="yearDate" :open="dataopen"
							style="margin-right: 10px;" format="YYYY" :allowClear="false" @openChange="openChangeYear"
							@panelChange="panelChangeYear" /> -->
						<a-select allowClear v-model="time" style="width: 120px;margin-right: 10px;margin-bottom: 10px;"
							@change="statusChange">
							<a-select-option v-for="item in timeList" :key="item.id">
								{{ item.name }}
							</a-select-option>
						</a-select>
						<a-select allowClear v-model="typeCode"
							style="width: 120px;margin-right: 10px;margin-bottom: 10px;" @change="statusChange1">
							<a-select-option v-for="item in typeList" :key="item.id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-spin :spinning="isLoading">
					<div id="data" style="height: 0px;text-align: center;font-size: 2rem;line-height: 100px;"></div>
					<div style="height: 720px;width: 100%;text-align: left" id="mainScrapCode">

					</div>
					<div id="pie" style="height: 0px;width: 100%;text-align: left" v-if="typeCode == 2 || typeCode == 3"></div>
				</a-spin>
			</a-card>
		</a-config-provider>
	</div>
</template>
<script>
	import {
		AppComponentBase
	} from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import {
		IssueServiceProxy
	} from '../../../shared/service-proxies';
	import moment from "moment";
	import * as echarts from 'echarts';

	let _this;
	export default {
		name: "issue-reports",
		mixins: [AppComponentBase],
		components: {

		},
		created() {
			_this = this;
			this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.function();
			this.getData();
		},
		data() {
			return {
				zh_CN,
				isEdit: false,
				isLook: false,
				isLoading: true, //加载中
				year: moment(),
				yearDate: undefined,
				nowTime: [],
				dataopen: false,
				timeList: [{
						name: this.l('Queries_by_month'),
						id: 1
					},
					{
						name: this.l('Queries_by_week'),
						id: 2
					}
				],
				time: 1,
				typeList: [{
						name: this.l('CloseRate') + "(%)",
						id: 1
					},
					{
						name: this.l('problem-classification'),
						id: 2
					},
					{
						name: this.l('IssueArea'),
						id: 3
					},
					{
						name: this.l('Issue_discoverer'),
						id: 4
					},
					{
						name: this.l('Issue_leader'),
						id: 5
					},
					{
						name: this.l('audit-template'),
						id: 6
					},
					{
						name: this.l('audit-item-templates'),
						id: 7
					}
				],
				typeCode: 1,
				month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				chartData: [],
				chartWeek: undefined,
				data: undefined,
				issueTitle: undefined,
				issueTypeTitle: undefined,
				xname: undefined,
				projectList: undefined,
				regionList: undefined,
				probType: undefined,
				bcBySeriesIndex: undefined,
				issueAreaTitle: undefined,
				proList: undefined,
				leaderHeadTitle: undefined,
				discovererHeadTitle: undefined,
				areaList: [],
				problemNum: [],
				auditPointTitle: undefined,
				auditItemTitle: undefined,
				pieTitle: undefined,
				pieDate: [],
				startDate: undefined,
				endDate: undefined,
				colors: [],
				pieColors: []
			}
		},
		computed: {
			// 指定图表的配置项和数据
			option() {
				return {
					title: {
						text: this.issueTitle,
						subtext: '',
						x: 'center',
						textStyle: {
							fontSize: 26,
						},
					},
					tooltip: {
						trigger: 'axis',
					},
					legend: {
						// data: [this.l('FindNum'), this.l('CloseNum'), this.l('CloseRate')],
						top: '97%',
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						name: '   ' + this.xname,
						data: this.chartWeek,
						splitLine: {
							show: true
						}
					}, ],
					yAxis: [{
							type: 'value',
							name: this.l('Count'),
							min: 0,
							axisLabel: {
								formatter: '{value}',
							},
						},
						{
							type: 'value',
							name: this.l('Issue_CloseRate') + "(%)",
							min: 0,
							max: 100,
							axisLabel: {
								formatter: '{value}%',
							},
						},
					],
					series: this.chartData,
				}
			},
			option1() {
				return {
					title: {
						text: this.issueTypeTitle,
						subtext: '',
						x: 'center',
						textStyle: {
							fontSize: 26,
						},
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							// 坐标轴指示器，坐标轴触发有效
							type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
						},
						//      formatter: params => {
						//        var html = [];

						//        var category = {};
						//        echarts['util'].each(params, function(param) {
						//          var catName = param.seriesName;
						// console.log(param.seriesIndex);
						//          var bc = this.bcBySeriesIndex[param.seriesIndex];
						//          if (!category[catName]) {
						//            category[catName] = {};
						//          }
						//          category[catName][bc] = param.value;
						//        });
						//        echarts['util'].each(category, function(cate, key) {
						//          html.push(
						//            '<tr>',
						//            '<td>',
						//            key,
						//            '</td>',
						//            '<td>',
						//            cate.A,
						//            '</td>',
						//            '<td>',
						//            cate.B,
						//            '</td>',
						//            '</tr>',
						//          );
						//        });

						//        return (
						//          '<table border=1><tbody>' +
						//          '<tr><td></td><td>A(Find发现)</td><td>B(Close关闭)</td></tr>' +
						//          html.join('') +
						//          '</tbody></table>'
						//        );
						//      },
					},
					legend: {
						data: this.projectList,
						top: '90%',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '10%',
						containLabel: true,
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.chartWeek,
						name: '   ' + this.xname,
						splitLine: {
							show: true
						}
					}, ],
					yAxis: [{
						type: 'value',
						name: this.l('Count(pcs)'),
					}, ],
					series: this.chartData,
				}
			},
			option2() {
				return {
					title: {
						text: this.issueAreaTitle,
						subtext: '',
						x: 'center',
						textStyle: {
							fontSize: 26,
						},
					},
					tooltip: {
						trigger: 'axis',
					},
					legend: {
						data: this.regionList,
						top: '90%',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '10%',
						containLabel: true,
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						name: '   ' + this.xname,
						data: this.chartWeek,
						splitLine: {
							show: true,
						}
					}, ],
					yAxis: [{
						type: 'value',
						name: this.l('Count(pcs)'),
					}, ],
					series: this.chartData,
				}
			},
			option3() {
				return {
					title: {
						text: this.discovererHeadTitle,
						subtext: '',
						x: 'center',
						textStyle: {
							fontSize: 26,
						},
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							// 坐标轴指示器，坐标轴触发有效
							type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
						},
						formatter: params => {

                                let result="";
                                for (let i = 0; i < params.length; i++) {
                                    if(params[i].data!=0 && params[i].data!=null){
                                        result+=params[i].marker + params[i].seriesName + '：' + params[i].data + '<br>'
                                    }
                                }
                                return params[0].name + '<br/>'
                                    + result
                                },
					},
					legend: {
						data: this.proList,
						top: '97%',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true,
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.chartWeek,
						name: '   ' + this.xname,
						splitLine: {
							show: true
						}
					}, ],
					yAxis: [{
						type: 'value',
						name: this.l('Count(pcs)'),
					}, ],
					series: this.chartData,
				}
			},
			option4() {
				return {
					title: {
						text: this.leaderHeadTitle,
						subtext: '',
						x: 'center',
						textStyle: {
							fontSize: 26,
						},
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							// 坐标轴指示器，坐标轴触发有效
							type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
						},
						  formatter: params => {

                            let result="";
                            for (let i = 0; i < params.length; i++) {
                                if(params[i].data!=0 && params[i].data!=null){
                                    result+=params[i].marker + params[i].seriesName + '：' + params[i].data + '<br>'
                                }
                            }
                            return params[0].name + '<br/>'
                                + result
						  },
					},
					legend: {
						data: this.proList,
						top: '97%',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true,
					},
					calculable: true,
					xAxis: [{
						type: 'category',
						data: this.chartWeek,
						name: '   ' + this.xname,
						splitLine: {
							show: true
						}
					}, ],
					yAxis: [{
						type: 'value',
						name: this.l('Count(pcs)'),
					}, ],
					series: this.chartData,
				}
			},
			option5() {
				return {
					title: {
					  text:
					    this.auditPointTitle,
					  subtext: '',
					  x: 'center',
					  textStyle: {
					    fontSize: 26,
					  },
					},
					tooltip: {
					  trigger: 'axis',
					  axisPointer: {
					    type: 'shadow',
					  },
					},
					legend: {
					  top: this.areaList.length == 1 ? '88%' : '90%',
					  x: 'center', //判断区域个数计算高度
					  data: [this.l('ProblemNum')],
					},
					grid: {
					  bottom: this.areaList.length == 1 ? '16%' : '12%',
					  containLabel: true,
					},
					calculable: true,
					xAxis: {
					  position: 'top',
					  minInterval: 1,
					  type: 'value',
					  boundaryGap: [0, 0.01],
					},
					yAxis: {
					  type: 'category',
					  data: this.areaList,
					  inverse: true,
					},
					series: [
					  {
					    name: this.l('ProblemNum'),
					    type: 'bar',
					    barMaxWidth: 50, //最大宽度
					    data: this.problemNum,
						label: {
						    show: true,
						    formatter:function(v){
								return v.data.label
							}
						},
					  },
					],
				}
			},
			option6() {
				return {
					title: {
					  text:
					    this.auditItemTitle,
					  subtext: '',
					  x: 'center',
					  textStyle: {
					    fontSize: 26,
					  },
					},
					tooltip: {
					  trigger: 'axis',
					  axisPointer: {
					    type: 'shadow',
					  },
					},
					legend: {
					  top: this.areaList.length == 1 ? '88%' : '90%',
					  x: 'center', //判断区域个数计算高度
					  data: [this.l('ProblemNum')],
					},
					grid: {
					  bottom: this.areaList.length == 1 ? '16%' : '12%',
					  containLabel: true,
					},
					calculable: true,
					xAxis: {
					  position: 'top',
					  minInterval: 1,
					  type: 'value',
					  boundaryGap: [0, 0.01],
					},
					yAxis: {
					  type: 'category',
					  data: this.areaList,
					  inverse: true,
					},
					series: [
					  {
					    name: this.l('ProblemNum'),
					    type: 'bar',
					    barMaxWidth: 50, //最大宽度
					    data: this.problemNum,
						label: {
						    show: true,
						    formatter:function(v){
								return v.data.label
							}
						},
					  },
					],
				}
			},
			option7() {
				return {
					title: {
					  text:
					    this.pieTitle,
					  subtext: '',
					  x: 'center',
					  textStyle: {
					    fontSize: 26,
					  },
					},
					tooltip: {
					  trigger: 'item',
					  formatter: function (a) {
					      return (
					          a.name +
					          "：" +
					          a.value +
					          " (" +
					          a.percent.toFixed(1) +
					          "%)"
					      );
					  }
					},
					color: ['#5470c6','#91cc75','#fac858','#ee6666','#73c0de','#3ba272','#fc8452','#9a60b4','#ea7ccc','#ff8989'],
					legend: {
					  type: 'scroll',
					  orient: 'vertical',
					  left: 'right',
					  top: '35%'
					},
					series: [
					  {
					    type: 'pie',
					    radius: '80%',
						center: ['50%', '52%'],
					    data: this.pieDate,
					    emphasis: {
					      itemStyle: {
					        shadowBlur: 10,
					        shadowOffsetX: 0,
					        shadowColor: 'rgba(0, 0, 0, 0.5)'
					      }
					    }
					  },
					  {
					    type: 'pie',
					    radius: '80%',
						center: ['50%', '52%'],
					    data: this.pieDate,
						label: {
							normal: {
								show: true,
								position: 'inside',
								formatter: function (params) {
								    return Math.round(params.percent * 10) / 10 + '%';
								}
							}
						}
					  }
					]
				}
			},
		},
		methods: {
			moment,
			getData() {
				this.isLoading = true;
				this.IssueServiceProxy.getIssueReport(
					this.startDate,
					this.endDate,
					this.time,
					this.typeCode
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					console.log(res);
					if (this.time == 1) {
						this.chartWeek = this.month;
						this.issueTitle = this.l('CloseRate') + '(' + this.l('month') + ')';
						this.issueTypeTitle = this.l('issueclassify') + '(' + this.l('month') + ')';
						this.issueAreaTitle = this.l('IssueArea') + '(' + this.l('month') + ')';
						this.leaderHeadTitle = this.l('Issue_leader') + '(' + this.l('month') + ')';
						this.discovererHeadTitle = this.l('Issue_discoverer') + '(' + this.l('month') + ')';
						this.xname = this.l('month');
						this.auditItemTitle = this.l('audit-item-templates') + '(' + this.l('month') + ')';
						this.auditPointTitle = this.l('audit-template') + '(' + this.l('month') + ')';
					} else {
						this.chartWeek = res.weeks;
						this.issueTitle = this.l('CloseRate') + '(' + this.l('week') + ')';
						this.issueTypeTitle = this.l('issueclassify') + '(' + this.l('week') + ')';
						this.issueAreaTitle = this.l('IssueArea') + '(' + this.l('week') + ')';
						this.leaderHeadTitle = this.l('Issue_leader') + '(' + this.l('week') + ')';
						this.discovererHeadTitle = this.l('Issue_discoverer') + '(' + this.l('week') + ')';
						this.xname = this.l('week');
						this.auditItemTitle = this.l('audit-item-templates') + '(' + this.l('week') + ')';
						this.auditPointTitle = this.l('Issue_leader') + '(' + this.l('week') + ')';
					}

					this.data = res.data;
					if (this.typeCode == 1) {
						this.chartsData1();
					} else if (this.typeCode == 2) {
						this.probType = res.probTypeList;
						this.pieDate = res.pieChartData;
						this.pieTitle = this.l('issueclassify') + "TOP10";
						this.chartsData2();
						this.pieChart();
					} else if (this.typeCode == 3) {
						// this.probType = res.probTypeList;
						this.chartsData3();
						this.pieDate = res.pieChartData;
						this.pieTitle = this.l('IssueArea') + "TOP10";
						this.pieChart();
					} else if (this.typeCode == 4) {
						this.proList = res.userList;
						this.chartsData4();
					} else if (this.typeCode == 5) {
						if(this.time == 1) {
							this.proList = res.probTypeList;
						}else {
							this.proList = res.userList;
						}
						this.chartsData5();
					} else if (this.typeCode == 6) {
						this.chartsData6();
					} else if (this.typeCode == 7) {
						this.chartsData7();
					}
					// this.chartsData();
				})
			},

			chartsData1() {
				let discoveriesNumber = {
					name: this.l('Identify_Issue'),
					type: 'bar',
					label: {
					    show: true,
					    position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let closeNumber = {
					name: this.l('CloseIssue'),
					type: 'bar',
					label: {
					    show: true,
					    position: 'top',
						formatter: function (params) {
						    if (params.value > 0) {
						        return params.value;
						    }else {
						        return '';
						    }
						}
					}
				};
				let closeRate = {
					name: this.l('Issue_CloseRate'),
					type: 'line',
					yAxisIndex : 1,
					yAxisIndex : 1,
					label: {
						show: true,
						textBorderWidth: '0',
						formatter: function(data){
							if(data.value>0) {
								return data.value + '%'
							}else {
								return ""
							}
						}
					}
				};
				let discoveriesdata = [];
				let closedata = [];
				let closeRatedata = [];
				for (let i = 0; i < this.chartWeek.length; i++) {
					discoveriesdata.push(0);
					closedata.push(0);
					closeRatedata.push(0);
					let number = i + 1;
					for (let l = 0; l < this.data.length; l++) {
						if (this.time == 1) {
							if (number == this.data[l].month) {
								discoveriesdata[i] = this.data[l].problemCount;
								closedata[i] = this.data[l].closeCount;
								closeRatedata[i] = this.data[l].closePercent;
							} else {

							}
						} else {
							if (number == this.data[l].week) {
								discoveriesdata[i] = this.data[l].problemCount;
								closedata[i] = this.data[l].closeCount;
								closeRatedata[i] = this.data[l].closePercent;
							} else {

							}
						}
					}
				}
				discoveriesNumber.data = discoveriesdata;
				closeNumber.data = closedata;
				closeRate.data = closeRatedata;
				this.chartData = [];
				this.chartData.push(discoveriesNumber);
				this.chartData.push(closeNumber);
				this.chartData.push(closeRate);
				console.log(this.chartData);
				this.charts();
			},

			chartsData2() {
				this.projectList = this.probType;
				this.chartData = [];
				// this.bcBySeriesIndex = [];
				// for (var a = 0; a < this.probType.length; a++) {
				// 	this.bcBySeriesIndex.push('A');
				// 	this.bcBySeriesIndex.push('B');
				// }
				for (var i = 0; i < this.probType.length; i++) {
					var name = this.probType[i];
					if (this.time == 1) {
						var obj = {
							name: name,
							type: 'bar',
							barWidth: 30,
							stack: 'A',
							data: [],
							label: {
							    normal: {
							        show: true,
							        position: 'inside',
							        formatter: (data) => {
										return data.value
									},
							    },
							}
						};
					} else {
						var obj = {
							name: name,
							type: 'bar',
							barWidth: 10,
							stack: 'A',
							data: [],
							label: {
							    normal: {
							        show: true,
							        position: 'inside',
							        formatter: (data) => {
										return data.value
									},
							    },
							}
						};
					}

					for (var w = 1; w <= this.chartWeek.length; w++) {
						var val = null;
						for (var li in this.data) {
							var d = this.data[li];
							if (this.time == 1) {
								if (name) {
									if (d.month == w && d.name == name) {
										val = d.problemCount;
									}
								} else {
									if (d.month == w) {
										val = d.problemCount;
									}
								}
							} else {
								if (name) {
									if (d.week == w && d.name == name) {
										val = d.problemCount;
									}
								} else {
									if (d.week == w) {
										val = d.problemCount;
									}
								}
							}
						}
						obj.data.push(val);
					}
					this.chartData.push(obj);
				}

				this.charts();
			},

			chartsData3() {
				this.chartData = [];
				let list = [];
				for(let a in this.data) {
					list.push(this.data[a].name)
				}
				this.regionList = Array.from(new Set(list));
				for (var i = 0; i < this.regionList.length; i++) {
					var name = this.regionList[i];
					if (this.time == 1) {
						var obj = {
							name: name,
							type: 'bar',
							barWidth: 30,
							stack: 'A',
							data: [],
							label: {
							    normal: {
							        show: true,
							        position: 'inside',
							        formatter: (data) => {
										return data.value
									},
							    },
							}
						};
					} else {
						var obj = {
							name: name,
							type: 'bar',
							barWidth: 10,
							stack: 'A',
							data: [],
							label: {
							    normal: {
							        show: true,
							        position: 'inside',
							        formatter: (data) => {
										return data.value
									},
							    },
							}
						};
					}
				
					for (var w = 1; w <= this.chartWeek.length; w++) {
						var val = null;
						for (var li in this.data) {
							var d = this.data[li];
							if (this.time == 1) {
								if (name) {
									if (d.month == w && d.name == name) {
										val = d.problemCount;
									}
								} else {
									if (d.month == w) {
										val = d.problemCount;
									}
								}
							} else {
								if (name) {
									if (d.week == w && d.name == name) {
										val = d.problemCount;
									}
								} else {
									if (d.week == w) {
										val = d.problemCount;
									}
								}
							}
						}
						obj.data.push(val);
					}
					this.chartData.push(obj);
				}
				this.charts();
			},

			chartsData4() {
				this.chartData = [];
				for (var i = 0; i < this.proList.length; i++) {
					var name = this.proList[i];
					for (var i = 0; i < this.proList.length; i++) {
						var name = this.proList[i];
						if (this.time == 1) {
							var obj = {
								name: name,
								type: 'bar',
								barWidth: 30,
								stack: 'A',
								data: [],
								label: {
									normal: {
										show: true,
										position: 'inside',
										formatter: (data) => {
											return data.value
										},
									},
								}
							};
						} else {
							var obj = {
								name: name,
								type: 'bar',
								barWidth: 10,
								stack: 'A',
								data: [],
								label: {
									normal: {
										show: true,
										position: 'inside',
										formatter: (data) => {
											return data.value
										},
									},
								}
							};
						}

						for (var w = 1; w <= this.chartWeek.length; w++) {
							var val = null;
							for (var li in this.data) {
								var d = this.data[li];
								if (this.time == 1) {
									if (name) {
										if (d.month == w && d.name == name) {
											val = d.problemCount;
										}
									} else {
										if (d.month == w) {
											val = d.problemCount;
										}
									}
								} else {
									if (name) {
										if (d.week == w && d.name == name) {
											val = d.problemCount;
										}
									} else {
										if (d.week == w) {
											val = d.problemCount;
										}
									}
								}
							}
							obj.data.push(val);
						}
						this.chartData.push(obj);

						if (this.time == 1) {
							var clo = {
								name: name,
								type: 'bar',
								barWidth: 30,
								stack: 'B',
								data: [],
								label: {
									normal: {
										show: true,
										position: 'inside',
										formatter: (data) => {
											return data.value
										},
									},
								}
							};
						} else {
							var clo = {
								name: name,
								type: 'bar',
								barWidth: 10,
								stack: 'B',
								data: [],
								label: {
									normal: {
										show: true,
										position: 'inside',
										formatter: (data) => {
											return data.value
										},
									},
								}
							};
						}
						for (var z = 1; z <= 12; z++) {
							var proc = null;
							for (var li in this.data) {
								var d = this.data[li];
								if (this.time == 1) {
									if (name) {
										if (d.month == w && d.name == name) {
											proc = d.problemCount;
										}
									} else {
										if (d.month == w) {
											proc = d.problemCount;
										}
									}
								} else {
									if (name) {
										if (d.week == w && d.name == name) {
											proc = d.problemCount;
										}
									} else {
										if (d.week == w) {
											proc = d.problemCount;
										}
									}
								}
							}
							clo.data.push(proc);
						}
						this.chartData.push(clo);
					}
				}
				this.charts();
			},

			chartsData5() {
				this.chartData = [];
				for (var i = 0; i < this.proList.length; i++) {
					var name = this.proList[i];
					for (var i = 0; i < this.proList.length; i++) {
						var name = this.proList[i];
						if (this.time == 1) {
							var obj = {
								name: name,
								type: 'bar',
								barWidth: 30,
								stack: 'A',
								data: [],
								label: {
									normal: {
										show: true,
										position: 'inside',
										formatter: (data) => {
											return data.value
										},
									},
								}
							};
						} else {
							var obj = {
								name: name,
								type: 'bar',
								barWidth: 10,
								stack: 'A',
								data: [],
								label: {
									normal: {
										show: true,
										position: 'inside',
										formatter: (data) => {
											return data.value
										},
									},
								}
							};
						}
			
						for (var w = 1; w <= this.chartWeek.length; w++) {
							var val = null;
							for (var li in this.data) {
								var d = this.data[li];
								if (this.time == 1) {
									if (name) {
										if (d.month == w && d.name == name) {
											val = d.problemCount;
										}
									} else {
										if (d.month == w) {
											val = d.problemCount;
										}
									}
								} else {
									if (name) {
										if (d.week == w && d.name == name) {
											val = d.problemCount;
										}
									} else {
										if (d.week == w) {
											val = d.problemCount;
										}
									}
								}
							}
							obj.data.push(val);
						}
						this.chartData.push(obj);
			
						if (this.time == 1) {
							var clo = {
								name: name,
								type: 'bar',
								barWidth: 30,
								stack: 'B',
								data: [],
								label: {
									normal: {
										show: true,
										position: 'inside',
										formatter: (data) => {
											return data.value
										},
									},
								}
							};
						} else {
							var clo = {
								name: name,
								type: 'bar',
								barWidth: 10,
								stack: 'B',
								data: [],
								label: {
									normal: {
										show: true,
										position: 'inside',
										formatter: (data) => {
											return data.value
										},
									},
								}
							};
						}
						for (var z = 1; z <= 12; z++) {
							var proc = null;
							for (var li in this.data) {
								var d = this.data[li];
								if (this.time == 1) {
									if (name) {
										if (d.month == w && d.name == name) {
											proc = d.problemCount;
										}
									} else {
										if (d.month == w) {
											proc = d.problemCount;
										}
									}
								} else {
									if (name) {
										if (d.week == w && d.name == name) {
											proc = d.problemCount;
										}
									} else {
										if (d.week == w) {
											proc = d.problemCount;
										}
									}
								}
							}
							clo.data.push(proc);
						}
						this.chartData.push(clo);
					}
				}
				this.charts();
			},

			chartsData6() {
				this.areaList = [];
				this.problemNum = [];
				this.data.forEach((item, index) => {
				  //循环赋值数据
				  this.areaList.push(item.name);
				  let data = {};
				  if(this.time == 1) {
					  data.value = item.problemCount;
					  data.label = item.month + this.l('month');
				  }else {
					  data.value = item.problemCount;
					  data.label = item.week + this.l('week');
				  }
				  this.problemNum.push(data);
				});
				if(this.areaList.length == 0) {
					this.myeChart.clear();
					document.getElementById("data").style.height = "200px";
					document.getElementById("data").innerHTML = this.l('NoData');
					document.getElementById("mainScrapCode").style.height = "0px";
				}else {
					if(this.areaList.length == 1) {
						document.getElementById("mainScrapCode").style.height = "200px";
					}else if(this.areaList.length == 2){
						document.getElementById("mainScrapCode").style.height = "300px";
					}else if(this.areaList.length > 2){
						document.getElementById("mainScrapCode").style.height = "720px";
					}
					
					this.charts();
				}
				
			},
			
			chartsData7() {
				this.areaList = [];
				this.problemNum = [];
				this.data.forEach((item, index) => {
				  //循环赋值数据
				  this.areaList.push(item.name);
				  let data = {};
				  if(this.time == 1) {
				  	  data.value = item.problemCount;
				  	  data.label = item.month + this.l('month');
				  }else {
				  	  data.value = item.problemCount;
				  	  data.label = item.week + this.l('week');
				  }
				  this.problemNum.push(data);
				});
				if(this.areaList.length == 0) {
					this.myeChart.clear();
					document.getElementById("data").style.height = "200px";
					document.getElementById("data").innerHTML = this.l('NoData');
					document.getElementById("mainScrapCode").style.height = "0px";
				}else {
					if(this.areaList.length == 1) {
						document.getElementById("mainScrapCode").style.height = "200px";
					}else if(this.areaList.length == 2){
						document.getElementById("mainScrapCode").style.height = "300px";
					}else if(this.areaList.length > 2){
						document.getElementById("mainScrapCode").style.height = "720px";
					}
					
					this.charts();
				}
				
			},
			

			charts() {
				this.myeChart = echarts.init(document.getElementById("mainScrapCode"))

				this.myeChart.clear();
				
				document.getElementById("data").style.height = "0px";
				document.getElementById("data").innerHTML = "";
				
				// 使用刚指定的配置项和数据显示图表。
				if (this.typeCode == 1) {
					document.getElementById("mainScrapCode").style.height = "720px";
					this.myeChart.setOption(this.option);
				} else if (this.typeCode == 2) {
					document.getElementById("mainScrapCode").style.height = "720px";
					this.myeChart.setOption(this.option1);
				} else if (this.typeCode == 3) {
					document.getElementById("mainScrapCode").style.height = "720px";
					this.myeChart.setOption(this.option2);
				} else if (this.typeCode == 4) {
					document.getElementById("mainScrapCode").style.height = "720px";
					this.myeChart.setOption(this.option3);
				} else if (this.typeCode == 5) {
					document.getElementById("mainScrapCode").style.height = "720px";
					this.myeChart.setOption(this.option4);
				} else if (this.typeCode == 6) {
					this.myeChart.setOption(this.option5);
				} else if (this.typeCode == 7) {
					this.myeChart.setOption(this.option6);
				}
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			pieChart() {
				this.myeChart = echarts.init(document.getElementById("pie"));
				
				this.myeChart.clear();
				
				document.getElementById("pie").style.height = "500px";
				this.myeChart.setOption(this.option7);
				
				this.myeChart.resize();
				window.addEventListener('resize', () => {
					this.myeChart.resize();
				})
			},
			
			statusChange(value) {
				this.time = value;
				this.getData();
			},
			statusChange1(value) {
				this.typeCode = value;
				this.getData();
			},
			function() {
				// this.yearDate = moment(new Date());
				let startTime = new Date().getFullYear() + '-01-01';
				let endTime = new Date().getFullYear() + '-12-31';
				this.nowTime = [moment(startTime), moment(endTime)];
				this.startDate = moment(startTime);
				this.endDate = moment(endTime);
			},
			
			//日期选择
			DiscoverytimeChange(date, dateString) {
				this.startDate = date[0];
				this.endDate = date[1];
				this.getData();
			},
			
			// // 点击选择框事件 弹出日期组件选择年
			// openChangeYear(status) {
			// 	if (status) {
			// 		this.dataopen = true;
			// 	} else {
			// 		this.dataopen = false;
			// 	}
			// },
			// // 选择年之后 关闭弹框
			// panelChangeYear(value) {
			// 	this.yearDate = value;
			// 	this.dataopen = false;
			// 	this.getData();
			// },
		}
	}
</script>

<style>
</style>
